<template>
    <div class="buildercard">
        <div class="buildercard-img">
            <img
                :src="
                    data.ref_image == null
                        ? require('assets/images/default-builder.png')
                        : data.ref_image
                "
                :alt="data.name"
            />
        </div>
        <div class="builder-details">
            <h1 class="buildercard-name">{{ data.name }}</h1>
            <p class="buildercard-description">
                {{ data.description.slice(0, 80) }}...
                <router-link
                    class="see-more"
                    :to="{
                        name: 'BuilderDetailsPage',
                        params: { id: data.id },
                    }"
                >
                    see more
                </router-link>
            </p>
        </div>
        
    </div>
</template>
<script>
export default {
    name: 'BuilderCard',
    props: {
        data: {
            type: Object,
        },
    },
}
</script>
<style lang="scss">
@import './BuilderCard.scss';
</style>
