<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader />
            </div>
        </template>
        <div class="builders-listpage">
            <!-- <Banner class="banner"
            img="builders_page/builderslistpage_banner_img.jpg"
            heading="Our Real Estate Partners"
        /> -->
            <div class="head-display">
                <div class="display-image">
                    <div class="img">
                        <!-- <img src="@/assets/images/FAQ_top.webp" alt=""> -->
                    </div>
                    <div class="display-info">
                        Our Real Estate Partners
                    </div>
                </div>
            </div>
            <div
                class="builderslist-container"
                v-if="Object.keys(buildersList).length > 0"
            >
                <div class="builderslist-row">
                    <div
                        v-for="builder in buildersList.developer_data"
                        :key="builder.id"
                        class="builderslist-col"
                    >
                        <router-link
                            :to="{
                                name: 'BuilderDetailsPage',
                                params: { id: builder.id },
                            }"
                        >
                            <BuilderCard :data="builder" />
                            <!-- <BuilderCard data="Rift" v-else/> -->
                        </router-link>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import BuildersListPageModule from 'store/modules/BuildersListPage'
import IsVisible from 'mixins/IsVisible'
import Footer from 'componentsv2/Footer'

// import Banner from 'components/Banner'
import BuilderCard from 'components/BuilderCard'
// import PaginateButton from 'components/PaginateButton'
// import PaginateLargeButton from 'components/PaginateLargeButton'
import Loader from 'componentsv2/Loader'
import loaderHandler from 'mixins/loader'

export default {
    name: 'BuildersListPage',
    components: {
        // Banner,
        BuilderCard,
        Footer,
        // PaginateButton,
        // PaginateLargeButton
        Loader,
    },
    mixins: [RegisterStoreModule, IsVisible, loaderHandler],
    data() {
        return {
            currentPage: 1,
        }
    },
    computed: {
        ...mapState({
            buildersList: state => state.BuildersListPageModule.buildersList,
        }),
    },
    created() {
        document.querySelector(
            "meta[property='og:title']",
        ).content = `Our Partners`

        document.querySelector(
            "meta[property='og:description']",
        ).content = `List of builders we've partnered with.`

        this.registerStoreModule(
            'BuildersListPageModule',
            BuildersListPageModule,
        )
        this.fetchBuildersList().then(res => {
            setTimeout(() => {
                this.loaderShow = false
            }, 2000)
        }).catch(err=>{
            setTimeout(() => {
                this.loaderShow = false
            }, 2000)
        })
    },
    destroyed() {
        this.$store.unregisterModule('BuildersListPageModule')
    },
    methods: {
        ...mapActions({
            fetchBuildersList: 'fetchBuildersList',
        }),
    },
}
</script>

<style lang="scss">
@import './BuildersListPage.scss';
</style>
