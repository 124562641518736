export default{
    methods:{
        isVisible(i , a , n , l){
    let res = false;
    let end_pad =0;
    let beg_pad = 0;
    let bar = (n-1)/2;
    let init = a-bar;
    let fin = a+bar;
    if(init<1){
        init = 1;
        end_pad = (bar -a +1); 
    }
    if(fin>l){
        fin = l;
        beg_pad = bar-(l-a);
    }
    if( (init-beg_pad)>=1){
        init = init-beg_pad;
    }
    if(fin+end_pad<=l){
        fin = fin+end_pad;
    }
    if(i>=init && i<=fin){
        res = true;
    }
    return res;
}

    }
} 