import Api from 'utils/Api'

export function getBuildersList(){
    return Api
        .get('RealEstateManagement/developer/')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getBuilderDetails({builder_id}){

    return Api
        .get(`RealEstateManagement/developer/${builder_id}/`)
        .then(response =>{
            return response.data
        })
        .catch(e => {
                return { status: e.response.status, data: e.response.data }
            })
}

export function getBuilderProjectDetails(project_id){
    return Api
        .get(`RealEstateManagement/developer-projects/project_details/?project_id=${project_id}`)
        .then(response =>{
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}