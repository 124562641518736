import { getBuildersList } from 'api/buildersApi'

const BuildersListPage = {
    state: ()=>({
        buildersList:[],
    }),

    mutations:{
        setBuildersList(state, payload){
            state.buildersList = payload
        },
    },

    getters:{
    },

    actions:{
        fetchBuildersList({commit}){
            return getBuildersList().then(payload => {
                if (payload.status === 404) {
                    console.log('fetching failed')
                } else {
                    commit('setBuildersList', payload)
                    return payload
                }
            })
        }
    }
}

export default BuildersListPage